/*新闻news-box卡片样式*/
.company-card {
    background: #F5F5F5;
    width: 100%;
}
/*新闻news-box卡片样式*/
.company-card:hover {
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
}

.company-card:active{
    /*transform: scale(0.99);*/
}
